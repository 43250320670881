import * as React from 'react';
import { PageNotFound } from '../components/PageNotFound';
import Layout from '../layouts/default';
import SEO from '../components/seo';
import { useEffect, useState } from 'react';

/* eslint react/jsx-pascal-case: "off" */
const NotFoundPage = () => {
  const [isMount, setMount] = useState(false);

  useEffect(() => setMount(true), []);

  // TODO: This is a workaround to prevent the 404 page from splashing on client side only routes. These will still load on the 404 page during hard refreshes.
  if (!isMount) {
    return (
      <Layout>
        <SEO title="Loading" data={{ title: 'Loading...', description: 'Loading...' }} />
      </Layout>
    );
  }

  return (
    <Layout>
      <SEO
        title="404: Not found"
        data={{ title: '404 - Hawk Not Found - StackHawk', description: '404 - Hawk Not Found - StackHawk' }}
      />
      <PageNotFound />
    </Layout>
  );
};

export default NotFoundPage;
