import React from 'react';
import './style.scss';

export const PageNotFound: React.FC = () => (
  <section className="not-found empty-nest w-100 position-relative container-fluid">
    <div className="container my-md-12 my-8">
      <div className="row pt-8">
        <div className="col-12">
          <h1 className="header text-asphalt mb-3 fw-900 fz-64">
            <span className="header text-asphalt bg-light px-4 mb-3 d-inline-block ">404</span>
            <br />
            <span className="header text-asphalt bg-light px-4 mb-3 d-inline-block ">Hawk Not Found</span>
          </h1>
        </div>
      </div>
    </div>
  </section>
);
